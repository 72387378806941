import React from 'react'
import PropTypes from 'prop-types'

import { navigate } from 'gatsby-link';
import { encode } from 'punycode';

class Main extends React.Component {

  state = {}

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
    .then(() => navigate(form.getAttribute("action")))
    .catch(error => console.log(error));
  }

  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Intro</h2>
          <p>I'm a Texas-born quant with a penchant for problem solving. After studying public policy and economics at Yale and Duke, I quickly returned to the Lone Star State to work as a financial and data analyst for three years.</p>
          <p>Wanting to accelerate my learning, I started finding clients outside my 8-5 to help with their analytics. I ultimately transitioned from my corporate role to growing a social media startup, running an e-commerce store, and managing multiple client engagements over the next few years.</p>
          <p>While working with a couple of software engineers on a project, I wasn't pleased with both my lack of programming knowledge and the results of my hires -- I realized I could do better. I started practicing online tutorials daily and was fortunate to receive a full-ride scholarship from Facebook to attend a web development bootcamp.</p>
          <p>After graduating in late 2018, I was quickly placed working as a software engineer for a Fortune 500 in Texas and took over the responsibilities
          from two external software engineering consultants. After migrating multiple backends from Windows servers to Kubernetes, I transitioned to working for a Bay Area fintech startup and am continuing on my journey to master all things coded-related.</p>
          {close}
        </article>

        <article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Work</h2>
          <p>The reason I've been so successful is that I'm constantly learning. If I don't know something upfront, I work harder than anyone I know to master it -- and deliver solutions to whatever problems my clients face.</p>
          <p>I offer clients:</p>
          <ul>
            <li>Strong blend of analytics and programming experiences</li>
            <li>Work ethic of a startup founder and entrepreneur</li>
            <li>Innate curiosity to study case law and IRS publications</li>
          </ul>
          <p>Some of my accomplishments include:</p>
          <ul>
            <li>Managed all business analytics for $250MM customer segment</li>
            <li>Grew social media startup to over $1MM in annual revenue</li>
            <li>Saved higher education client more than $500,000 annually in wasteful spending</li>
            <li>Sent over 15,000 personalized emails on behalf of clients to grow a pipeline of over $2.5MM in potential deals</li>
          </ul>
          {close}
        </article>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">About</h2>
          <p>Outside of work, I'm a voracious reader -- consuming dozens of non-fiction books per year.</p>
          <p>I enjoy partaking in the quantified self movement, or the idea that big data can be used to better understand ourselves. Leveraging my database skills, I've been able to maintain sleep logs, daily activity logs, as well as have all text messages saved since 2009. I'm constantly in the process of practicing new skills by expanding the metrics I track.</p>
          <p>My one weakness is a great movie, however. I've spent 135.6 hours per year watching a variety of films and don't see that figure changing anytime soon!</p>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>
          <form name="contact" method="post" action="#" data-netlify="true" netlify-honeypot="bot-field" onSubmit={this.handleSubmit}>
            <input type="hidden" name="form-name" value="contact" />
            <p class="hidden">
              <label>Don’t fill this out if you're human: <input name="bot-field" onChange={this.handleChange} /></label>
            </p>
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" onChange={this.handleChange} />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" onChange={this.handleChange} />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4" onChange={this.handleChange} ></textarea>
            </div>
            <div data-netlify-recaptcha="true"></div>
            <ul className="actions">
              <li><input type="submit" value="Send Message" className="special" /></li>
              <li><input type="reset" value="Reset" /></li>
            </ul>
          </form>
          <ul className="icons">
            <li><a href="https://www.linkedin.com/in/farverio" className="icon fa-linkedin"><span className="label">Twitter</span></a></li>
            <li><a href="https://gitlab.com/farverio" className="icon fa-gitlab"><span className="label">GitHub</span></a></li>
          </ul>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
